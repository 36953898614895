<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import ClipboardMixin from '@/mixins/clipboard.mixin';
import Swal from 'sweetalert2';

/**
 * General-ui component
 */
export default {
  page: {
    title: 'Deposit',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  mixins: [ClipboardMixin],
  data() {
    return {
      title: 'Deposit',
      type: 'crypto',
    };
  },
  methods: {
    checkStatus() {
      Swal.fire({
        title: 'Deposit made successfully',
        html:
          "<div class='avatar-md mx-auto'>" +
          "<span class='avatar-title rounded-circle bg-success bg-soft text-success font-size-28'>" +
          "<i class='bx bx-wink-smile'></i>" +
          ' </span>' +
          '</div>' +
          "<p class='text-muted mt-3 mb-3 font-size-14'>Itaque earum rerum hic tenetur a sapiente delectus ut aut reiciendis</p>",
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: 'Back to home',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-dark btn-sm w-lg',
          container: 'swal-padding',
        },
      }).then(() => {
        this.$router.push({ name: 'home' });
      });
    },
  },
  metaInfo() {
    return {
      title: this.$t('Deposit.Title'),
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="card card-transparent mb-4">
      <div class="row">
        <div class="col-xl-9 col-12">
          <div class="bg-white">
            <div class="form-title bordered">
              <div class="row">
                <div class="col-md-9 offset-md-3">Cryptocurrency payment</div>
              </div>
            </div>

            <div class="row pt-3 pb-3 mb-4">
              <div class="col-md-4 offset-md-4 text-center">
                <h4 class="card-title mt-3 mb-5">Cryptocurrency payment</h4>

                <h1 class="text-dark fw-bold mb-4">220 $</h1>

                <img
                  class="qr mb-4"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/250px-QR_code_for_mobile_English_Wikipedia.svg.png"
                  alt="qr"
                />

                <div
                  class="alert alert-success text-dark cursor-pointer mb-4"
                  role="alert"
                  @click="copyToClipboard('bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq')"
                >
                  bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq

                  <i class="bx bx-clipboard font-size-16 ms-2 align-middle"></i>
                </div>

                <p class="text-dark mb-4">
                  This blockchain address will be valid only for 4 hours. <br />
                  Only 1 transaction will be proceed.
                </p>

                <div class="text-center mb-4">
                  <button type="button" class="btn btn-dark btn-sm" @click="checkStatus">
                    Check transaction status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-12 description">
          <div class="form-title mb-3">Description</div>

          <p class="mb-5">
            Depositing, withdrawal and transferring funds operations are made according to the company's rates relevant
            as of the date of receipt of applications.
          </p>

          <div class="form-title mb-3">FAQ</div>

          <div class="accordion">
            <div v-for="index in 3" :key="index" class="faq-item">
              <button class="accordion-button fw-medium" type="button" v-b-toggle="`faq-${index}`">
                {{ index }}. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </button>

              <b-collapse :id="`faq-${index}`" accordion="faq" role="tabpanel">
                <b-card-body>
                  <b-card-text
                    >Depositing, withdrawal and transferring funds operations are made according to the company's rates
                    relevant as of the date of receipt of applications.</b-card-text
                  >
                </b-card-body>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped lang="scss">
.qr {
  width: 175px;
  height: 175px;
}

.description {
  > * {
    @media (max-width: 1200px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
